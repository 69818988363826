import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ColDef,
  GridApi,
  SideBarDef,
  ValueGetterParams,
} from "ag-grid-community";
import { format } from "date-fns";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  useDeletetMilestone,
  useGetMilestoneTableDetails,
} from "../../queries/milestone";
import {
  AddVestingMilestoneReq,
  MilestoneStatus,
  MilestoneType,
  VestingMilestoneTableDetails,
} from "../../types/milestone";
import { Box, ButtonPrimary, HStack, VStack } from "../../components/utils";
import StatusLabel from "../esopOverview/StatusLabel";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import AddOrEditMilestone from "./AddOrEditMilestone";
import { formatDate } from "../../utils/date";
import { sortComparator } from "../../utils/agGridUtils";
import AlertDialog from "../../components/shared/AlertDialog";
import GenericTableHeader from "../../shared/GenericTableHeader";
import { usePermissionStore } from "../../store";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  AclList,
  Action as ActionPerforming,
  Resource,
} from "../../utils/interfaces/Companies";
import { useTableStateManagement } from "../../components/shared/TableHook";
import { TableId } from "../../constants/TableIdConstants";
import CloudSetting from "../../components/shared/CloudIcon";
import SearchInput from "../../components/shared/SearchInput";

type TotalLinkedInfoProps = {
  total: number;
  pending: number;
  done: number;
  forEmployee: boolean;
};

const completeDisabledState = [
  MilestoneStatus.DRAFT.toString(),
  MilestoneStatus.COMPLETED.toString(),
];

export function MilestonesTable() {
  const navigate = useNavigate();
  const { data: _allMilestoneTableDetails, isFetched } =
    useGetMilestoneTableDetails();

  _allMilestoneTableDetails?.sort(
    (a, b) =>
      new Date(b.targetDate).getTime() - new Date(a.targetDate).getTime()
  );

  const allMilestoneTableDetails = useMemo(
    () => _allMilestoneTableDetails || [],
    [_allMilestoneTableDetails, isFetched]
  );

  const [filteredRowData, setFilteredRowData] = useState<any>(
    allMilestoneTableDetails
  );
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { permission } = usePermissionStore();
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: AddVestingMilestoneReq;
  }>({
    open: false,
    data: undefined,
  });

  const [mode, setMode] = useState<"Add" | "Edit" | "Delete">("Add");

  const [milestonesData, setMilestonesData] =
    useState<VestingMilestoneTableDetails[]>();
  const [searchText, setSearchText] = useState("");
  const gridApi = useRef<GridApi | any>(null);

  useEffect(() => {
    if (
      JSON.stringify(allMilestoneTableDetails) !==
      JSON.stringify(milestonesData)
    ) {
      setMilestonesData(allMilestoneTableDetails);
    }
  }, [isFetched]);

  useEffect(() => {
    if (allMilestoneTableDetails !== undefined) {
      setMilestonesData(allMilestoneTableDetails);
      setFilteredRowData(allMilestoneTableDetails);
    }
  }, [isFetched]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );

  function handleAction(milestone: any, action: Action) {
    if (action.name === "Edit" && !action.disabled) {
      setMode("Edit");
      const data: AddVestingMilestoneReq = {
        ...milestone,
        targetDate: formatDate(milestone.targetDate),
      };
      setDialog({
        open: true,
        data,
      });
    }
    if (action.name === "Delete" && !action.disabled) {
      setMode("Delete");
      const data: AddVestingMilestoneReq = {
        ...milestone,
        targetDate: formatDate(milestone.targetDate),
      };
      setDialog({
        open: true,
        data,
      });
    }
    if (
      (action.name === "Complete" || action.name === "Accelerate") &&
      !action.disabled
    ) {
      navigate(`/options/completeMilestone/${milestone.id}`);
    }
  }

  const { mutate: deleteMilestone } = useDeletetMilestone();

  function handleDelete() {
    const id = dialog.data?.id?.toString() ?? "";
    deleteMilestone(id, {
      onSuccess: (res) => {
        toast(res, {
          type: "success",
          autoClose: 2000,
        });
        setDialog({ open: false });
        setMode("Add");
      },
      onError: (err: any) => {
        toast(err.response.data.message, { type: "error", autoClose: 2000 });
        setDialog({ open: false });
        setMode("Add");
      },
    });
  }
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
  } = useTableStateManagement(gridApi, TableId.milestoneTable);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();
    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilter(filterModel);
  };

  const TotalLinkedInfo: React.FC<TotalLinkedInfoProps> = ({
    total,
    pending,
    done,
    forEmployee,
  }) => (
    <HStack className="px-2 py-2 align-top">
      <Box>
        <HStack className="">
          <p className="w-10 text-xs font-medium text-gray-dark">
            {parseFloat(total.toFixed(4))}
          </p>
        </HStack>
        {forEmployee ? (
          <HStack>
            <p className="leading-5 text-xxs text-gray-light">
              Done: {parseFloat(done.toFixed(4))} | Pending:{" "}
              {parseFloat(pending.toFixed(4))}
            </p>
          </HStack>
        ) : (
          <HStack>
            <p className="leading-5 text-xxs text-gray-light">
              Vested: {parseFloat(done.toFixed(4))} | Lapsed:{" "}
              {parseFloat(pending.toFixed(4))}
            </p>
          </HStack>
        )}
      </Box>
    </HStack>
  );

  const [colDefs] = useState<ColDef[]>([
    {
      headerName: "MILESTONE NAME",
      field: "milestoneName",
      sortable: true,
      cellStyle: { "line-height": "20px", "padding-top": "15px" },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      filterParams: {
        buttons: ["reset"],
        defaultToNothingSelected: true,
        suppressSelectAll: false,
        suppressMiniFilter: false,
        excelMode: "windows",
      },
      width: 200,
      menuTabs: ["filterMenuTab"],
      autoHeight: true,
      wrapText: true,
      filter: true,
    },
    {
      headerName: "TARGET DATE",
      field: "targetDate",
      valueFormatter: (params) => {
        const formattedDate = params.value
          ? format(new Date(params.value), "dd-MMM-yyyy")
          : "N/A";
        return formattedDate;
      },
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: ["filterMenuTab"],
      autoHeight: true,
      wrapText: true,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (dateFromFilter: Date, cellValue: any) => {
          const filterValue = new Date(dateFromFilter);
          const newValue = new Date(cellValue);
          filterValue.setHours(0, 0, 0, 0);
          newValue.setHours(0, 0, 0, 0);
          if (dateFromFilter.getTime() === newValue.getTime()) return 0;
          return dateFromFilter.getTime() > newValue.getTime() ? -1 : 1;
        },
      },
    },
    {
      headerName: "COMPLETION DATE",
      field: "completionDate",
      valueFormatter: (params) => {
        const formattedDate = params.value
          ? format(new Date(params.value), "dd-MMM-yyyy")
          : "N/A";
        return formattedDate;
      },
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: ["filterMenuTab"],
      autoHeight: true,
      wrapText: true,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (dateFromFilter: Date, cellValue: any) => {
          const filterValue = new Date(dateFromFilter);
          const newValue = new Date(cellValue);
          filterValue.setHours(0, 0, 0, 0);
          newValue.setHours(0, 0, 0, 0);
          if (dateFromFilter.getTime() === newValue.getTime()) return 0;
          return dateFromFilter.getTime() > newValue.getTime() ? -1 : 1;
        },
      },
    },
    {
      headerName: "EMPLOYEES",
      field: "employeesLinked",
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: TotalLinkedInfo,
      filter: "agMultiColumnFilter",
      menuTabs: ["filterMenuTab"],
      comparator(valueA, valueB, nodeA, nodeB, isDescending) {
        return sortComparator(
          valueA.props.total,
          valueB.props.total,
          nodeA,
          nodeB,
          isDescending
        );
      },
      filterParams: {
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Total Employees",
            display: "subMenu",
            filter: "agNumberColumnFilter",
            filterValueGetter: (params: ValueGetterParams) =>
              params?.getValue("employeesLinked")?.props?.total,
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params?.getValue("employeesLinked")?.props?.total,
            },
          },
          {
            title: "Done",
            display: "subMenu",
            filter: "agNumberColumnFilter",
            filterValueGetter: (params: ValueGetterParams) =>
              params?.getValue("employeesLinked")?.props?.done,
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params?.getValue("employeesLinked")?.props?.done,
            },
          },
          {
            title: "Pending",
            display: "subMenu",
            filter: "agNumberColumnFilter",
            filterValueGetter: (params: ValueGetterParams) =>
              params?.getValue("employeesLinked")?.props?.pending,
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params?.getValue("employeesLinked")?.props?.pending,
            },
          },
        ],
      },
    },
    {
      headerName: "TOTAL NO OF OPTIONS",
      field: "totalNoOfOptions",
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: TotalLinkedInfo,
      filter: "agMultiColumnFilter",
      menuTabs: ["filterMenuTab"],
      comparator(valueA, valueB, nodeA, nodeB, isDescending) {
        return sortComparator(
          valueA.props.total,
          valueB.props.total,
          nodeA,
          nodeB,
          isDescending
        );
      },
      filterParams: {
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Total No Of Options",
            display: "subMenu",
            filter: "agNumberColumnFilter",
            filterValueGetter: (params: ValueGetterParams) =>
              params?.getValue("totalNoOfOptions")?.props?.total,
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params?.getValue("totalNoOfOptions")?.props?.total,
            },
          },
          {
            title: "Vested",
            display: "subMenu",
            filter: "agNumberColumnFilter",
            filterValueGetter: (params: ValueGetterParams) =>
              params?.getValue("totalNoOfOptions")?.props?.done,
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params?.getValue("totalNoOfOptions")?.props?.done,
            },
          },
          {
            title: "Lapsed",
            display: "subMenu",
            filter: "agNumberColumnFilter",
            filterValueGetter: (params: ValueGetterParams) =>
              params?.getValue("totalNoOfOptions")?.props?.pending,
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params?.getValue("totalNoOfOptions")?.props?.pending,
            },
          },
        ],
      },
    },
    {
      headerName: "MILESTONE STATUS",
      field: "milestoneStatus",
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: StatusLabel,
      filterValueGetter: (params: ValueGetterParams) =>
        params?.getValue("milestoneStatus")?.props?.state,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: false,
        suppressMiniFilter: false,
        excelMode: "windows",
        buttons: ["reset"],
        valueGetter: (params: any) =>
          params?.getValue("milestoneStatus")?.props?.state,
      },
      menuTabs: ["filterMenuTab"],
      comparator(valueA, valueB, nodeA, nodeB, isDescending) {
        return sortComparator(valueA, valueB, nodeA, nodeB, isDescending);
      },
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: false,
      width: 80,
      maxWidth: 80,
      filter: false,
      colId: "action-column",
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      sortable: false,
      menuTabs: ["columnsMenuTab"],
      cellRendererParams: ({ value }: { value: any }) => value.props,
      cellRenderer: CTADropdown,
    },
  ]);

  const rowData = useMemo(() => {
    const allData = milestonesData?.map((milestone) => {
      const data = {
        id: milestone.id,
        milestoneName: milestone.milestoneName,
        targetDate: milestone.targetDate,
        completionDate: milestone.completionDate,
        employeesLinked: (
          <TotalLinkedInfo
            total={milestone.employeesLinked}
            pending={milestone.employeesPending}
            done={milestone.employeesLinked - milestone.employeesPending}
            forEmployee={true}
          />
        ),
        totalNoOfOptions: (
          <TotalLinkedInfo
            total={milestone.totalNoOfOptions}
            pending={milestone.totalNoOfOptionsLapsed}
            done={milestone.totalNoOfOptionsVested}
            forEmployee={false}
          />
        ),
        milestoneStatus: (
          <StatusLabel state={milestone.milestoneStatus.toString()} />
        ),
        closureType: milestone.closureType,
        milestoneType: milestone.milestoneType,
        actions: (
          <CTADropdown
            actions={[
              {
                name:
                  new Date(milestone.targetDate).getTime() >
                  new Date().getTime()
                    ? "Accelerate"
                    : "Complete",
                disabled:
                  milestone.milestoneStatus === MilestoneStatus.DRAFT ||
                  milestone.milestoneStatus === MilestoneStatus.COMPLETED ||
                  !determineUserAccessToResource(
                    permission?.aclList as AclList[],
                    Resource.Grant,
                    ActionPerforming.Edit
                  ),
              },
              {
                name: "Edit",
                disabled:
                  milestone.milestoneStatus !== MilestoneStatus.DRAFT ||
                  !determineUserAccessToResource(
                    permission?.aclList as AclList[],
                    Resource.Grant,
                    ActionPerforming.Create
                  ),
              },
              {
                name: "Delete",
                disabled: !(
                  milestone.milestoneStatus === MilestoneStatus.DRAFT ||
                  determineUserAccessToResource(
                    permission?.aclList as AclList[],
                    Resource.Grant,
                    ActionPerforming.Create
                  )
                ),
              },
            ]}
            onAction={(action) => {
              handleAction(milestone, action);
            }}
          />
        ),
      };
      return data;
    });
    return allData;
  }, [milestonesData]);

  return (
    <VStack className="lg:justify-start">
      <GenericTableHeader
        heading={"Milestones Table"}
        subHeading={""}
        customClassName="flex flex-col px-0 lg:justify-start"
        additionalInfo={<>{`${filteredRowData?.length} Milestones`}</>}
      ></GenericTableHeader>
      <HStack className="items-center justify-between w-full gap-4 py-4">
        <HStack className="items-center gap-4 ">
          <SearchInput
            className=""
            placeholder="Search"
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
        </HStack>
        <HStack className="items-center justify-end gap-4 mb-4">
          <CloudSetting
            tableId={TableId.milestoneTable}
            isLoading={isSaving}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
            gridApi={gridApi}
          />

          <HStack className="pr-3 ">
            <ButtonPrimary
              disabled={
                !determineUserAccessToResource(
                  permission?.aclList as AclList[],
                  Resource.Grant,
                  ActionPerforming.Create
                )
              }
              onClick={() => {
                setMode("Add");
                setDialog({ open: true });
              }}
            >
              Add a Milestone
            </ButtonPrimary>
          </HStack>
        </HStack>
      </HStack>
      <Box
        style={{ height: "calc(90vh - 100px)" }}
        className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
      >
        <AgGridReact
          rowData={rowData}
          quickFilterText={searchText}
          rowSelection="multiple"
          columnDefs={colDefs}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          defaultColDef={defaultColDef}
          animateRows={true}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          suppressMenuHide={true}
          tooltipShowDelay={1000}
          rowMultiSelectWithClick={true}
          onFilterChanged={onAgGridFilterChanged}
          onColumnEverythingChanged={uploadColumn}
          onColumnResized={uploadColumn}
          rowClass={"border-t border-dashed"}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
          }
          onGridReady={(params) => {
            // Store the grid API reference
            gridApi.current = params;
          }}
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: "#f8f8f8" };
            } else {
              return { background: "#ffffff" };
            }
          }}
        />
      </Box>
      <Dialog open={dialog.open} maxWidth="md">
        {mode === "Delete" ? (
          <AlertDialog
            open={dialog.open}
            message={"Are you sure you want to delete this Milestone"}
            onPrimaryAction={() => handleDelete()}
            onSecondaryAction={() => {
              setMode("Add");
              setDialog({ open: false });
            }}
          />
        ) : (
          <AddOrEditMilestone
            onClose={() => setDialog({ open: false })}
            data={dialog.data}
            mode={mode}
          />
        )}
      </Dialog>
    </VStack>
  );
}
